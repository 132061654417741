<template>
  <v-layout :fill-height="true" class="Login">
    <v-row align="center" justify="center">
      <v-col lg="3" md="5" sm="7">
        <v-card outlined>
          <v-card-title class="Login__logo-container pt-8">
            <img
              class="Login__logo-image"
              src="@/assets/images/shifthealth_login_logo.png"
              alt="Shifthealth.io"
            />
          </v-card-title>
          <v-card-text class="py-10">
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <v-row>
                  <v-col cols="11" class="mx-auto pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="token"
                    >
                      <v-text-field
                        label="Two Factor Token"
                        name="token"
                        flat
                        outlined
                        type="text"
                        :hide-details="false"
                        :error="errors.length > 0"
                        :error-count="errors.length"
                        :error-messages="errors"
                        v-model="token"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row v-if="hasError">
                  <v-col cols="11" class="mx-auto py-0">
                    <p class="error--text mb-0">
                      {{ errorMessage }}
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="11" class="mx-auto">
                    <v-btn
                      type="submit"
                      color="primary"
                      block
                      large
                      :loading="verifyingToken"
                      :disabled="verifyingToken"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </validation-observer>

            <v-row v-if="showResend">
              <v-col cols="11" class="mx-auto mt-5">
                Didn't receive the token?
                <v-btn
                  type="submit"
                  color="primary"
                  block
                  large
                  outlined
                  @click="resend"
                  :loading="resendingToken"
                  :disabled="resendingToken"
                >
                  Resend Token
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: AuthActions,
  mapGetters: AuthGetters,
  mapMutations: AuthMutation,
} = createNamespacedHelpers('auth');

export default {
  name: 'TwoFactor',
  components: {},
  data() {
    return {
      token: '',
      timeoutId: null,
      showResend: false,
    };
  },
  computed: {
    ...AuthGetters({
      verifyingToken: 'VERIFYING_TOKEN',
      hasError: 'VERIFY_TOKEN_ERROR',
      errorMessage: 'VERIFY_TOKEN_MESSAGE',
      resendingToken: 'RESENDING_TOKEN',
    }),
  },
  mounted() {
    this.triggerResend();
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    ...AuthActions(['verifyToken', 'resendToken']),
    ...AuthMutation({
      setShowPasswordResetDialog: 'SET_SHOW_PASSWORD_RESET_DIALOG',
    }),
    onSubmit() {
      this.verifyToken({ otp: this.token });
    },
    triggerResend() {
      this.showResend = false;
      this.timeoutId = setTimeout(() => {
        this.showResend = true;
      }, 1000 * 60); // 1 minute
    },
    resend() {
      this.resendToken();
      this.triggerResend();
    },
  },
};
</script>

<style lang="scss" scoped>
.Login {
  &__logo-container {
    display: flex;
    justify-content: center;
  }

  &__logo-image {
    width: 300px;
  }
}
</style>
